import { Table } from 'antd';
import React from 'react';

export const hostessRewardData = [
    {
        id: 1,
        name: 'Markéta Dušková',
        hostessNo: 'H5331',
        contract: 'ZL',
        hours: 147.5,
        totalEarnings: 3250,
        totalTravelCosts: 540,
        totalOtherCosts: 1000,
    },
    {
        id: 2,
        name: 'Ivana dybová',
        hostessNo: 'H5712',
        contract: 'DPP',
        hours: 300,
        totalEarnings: 3250,
        totalTravelCosts: 540,
        totalOtherCosts: 1000,
    },
];

export default class PeopleTotal extends React.Component {


    render() {

        let total = 0;
        hostessRewardData.forEach((finance, i) => {
            const row = finance.totalEarnings + finance.totalOtherCosts + finance.totalTravelCosts;
            total += row;
        });

        return (
            <div>

                <Table dataSource={hostessRewardData} pagination={false} rowKey={x => `${x.id}`}
                    columns={[
                        {
                            key: 'hNo',
                            title: 'Hostess No.',
                            render: (_, record) => <label className="pno-cell">{record.hostessNo}</label>,
                        },
                        {
                            key: 'name',
                            title: 'Name',
                            render: (_, record) => <>{record.name}</>,
                        },
                        {
                            key: 'contract',
                            title: 'Contract',
                            render: (_, record) => <>{record.contract}</>,
                        },
                        {
                            key: 'hours',
                            title: 'Hours',
                            align: 'right',
                            render: (_, record) => <>{record.hours} h</>,
                        },
                        {
                            key: 'totalEarnings',
                            title: 'Total Earnings',
                            align: 'right',
                            render: (_, record) => <>{record.totalEarnings} Kč</>,
                        },
                        {
                            key: 'totalTravelCosts',
                            title: 'Total Travel Costs',
                            align: 'right',
                            render: (_, record) => <>{record.totalTravelCosts} Kč</>,
                        },
                        {
                            key: 'totalOtherCosts',
                            title: 'Total Other Costs',
                            align: 'right',
                            render: (_, record) => <>{record.totalOtherCosts} Kč</>,
                        },
                        {
                            key: 'total',
                            title: 'Total',
                            className: 'financing-table-total',
                            align: 'right',
                            render: (_, record) => <>{record.totalEarnings + record.totalOtherCosts + record.totalTravelCosts} Kč</>,
                        },
                    ]}
                    summary={() => (
                        <Table.Summary>
                            <Table.Summary.Row className="financing-summary">
                                <Table.Summary.Cell index={0}>Součet</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                <Table.Summary.Cell index={3} className="ammount-align">{hostessRewardData.map(finance => finance.hours).reduce((sum, current) => sum + current)} h</Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="ammount-align">{hostessRewardData.map(finance => finance.totalEarnings).reduce((sum, current) => sum + current)} Kč</Table.Summary.Cell>
                                <Table.Summary.Cell index={5} className="ammount-align">{hostessRewardData.map(finance => finance.totalTravelCosts).reduce((sum, current) => sum + current)} Kč</Table.Summary.Cell>
                                <Table.Summary.Cell index={6} className="ammount-align">{hostessRewardData.map(finance => finance.totalOtherCosts).reduce((sum, current) => sum + current)} Kč</Table.Summary.Cell>
                                <Table.Summary.Cell index={7} className="ammount-align">{total} Kč</Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />

            </div>
        );
    }
}