import { Table } from 'antd';
import moment from 'moment';
import React from 'react';

export const hostessRewardData = [
    {
        id: 1,
        date: moment(new Date(2021, 6, 14)),
        pNo: 'P134/2019',
        name: 'Lindor Xmass',
        region: 'PR',
        rate: 110,
        hours: 14,
        totalEarnings: 3250,
        totalTravelCosts: 540,
        totalOtherCosts: 1000,
    },
    {
        id: 2,
        date: moment(new Date(2021, 6, 14)),
        pNo: 'P134/2019',
        name: 'Lindor Xmass',
        region: 'PR',
        rate: 130,
        hours: 7,
        totalEarnings: 3250,
        totalTravelCosts: 540,
        totalOtherCosts: 1000,
    },
];

export default class PersonDetail extends React.Component {

    render() {

        let total = 0;
        hostessRewardData.forEach((finance, i) => {
            const row = (finance.rate * finance.hours) + finance.totalEarnings + finance.totalOtherCosts + finance.totalTravelCosts;
            total += row;
        });

        return (
            <div>

                <div className="financial-info-container">
                    <div className="card-container">
                        <div className="card card-item one-third">
                            <label className="gray-small-label">Hostess</label>
                            <label className="data-label">Markéta Dušková</label>
                        </div>
                    </div>
                </div>

                <Table dataSource={hostessRewardData} pagination={false} rowKey={x => `${x.id}`}
                    columns={[
                        {
                            key: 'date',
                            title: 'Date',
                            render: (_, record) => <>{record.date.format('DD.MM.YYYY')}</>,
                        },
                        {
                            key: 'pNo',
                            title: 'P. No.',
                            render: (_, record) => <label className="pno-cell">{record.pNo}</label>,
                        },
                        {
                            key: 'name',
                            title: 'Name',
                            render: (_, record) => <>{record.name}</>,
                        },
                        {
                            key: 'region',
                            title: 'Region',
                            render: (_, record) => <>{record.region}</>,
                        },
                        {
                            key: 'rate',
                            title: 'Rate',
                            align: 'right',
                            render: (_, record) => <>{record.rate} Kč/h</>,
                        },
                        {
                            key: 'hours',
                            title: 'Hours',
                            align: 'right',
                            render: (_, record) => <>{record.hours} h</>,
                        },
                        {
                            key: 'totalEarnings',
                            title: 'Total Earnings',
                            align: 'right',
                            render: (_, record) => <>{record.totalEarnings} Kč</>,
                        },
                        {
                            key: 'totalTravelCosts',
                            title: 'Total Travel Costs',
                            align: 'right',
                            render: (_, record) => <>{record.totalTravelCosts} Kč</>,
                        },
                        {
                            key: 'totalOtherCosts',
                            title: 'Total Other Costs',
                            align: 'right',
                            render: (_, record) => <>{record.totalOtherCosts} Kč</>,
                        },
                        {
                            key: 'total',
                            title: 'Total',
                            className: 'financing-table-total',
                            align: 'right',
                            render: (_, record) => <>{(record.rate * record.hours) + record.totalEarnings + record.totalOtherCosts + record.totalTravelCosts} Kč</>,
                        },
                    ]}
                    summary={() => (
                        <Table.Summary>
                            <Table.Summary.Row className="financing-summary">
                                <Table.Summary.Cell index={0}>Součet</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                <Table.Summary.Cell index={5} className="ammount-align">{hostessRewardData.map(finance => finance.hours).reduce((sum, current) => sum + current)} h</Table.Summary.Cell>
                                <Table.Summary.Cell index={6} className="ammount-align">{hostessRewardData.map(finance => finance.totalEarnings).reduce((sum, current) => sum + current)} Kč</Table.Summary.Cell>
                                <Table.Summary.Cell index={7} className="ammount-align">{hostessRewardData.map(finance => finance.totalTravelCosts).reduce((sum, current) => sum + current)} Kč</Table.Summary.Cell>
                                <Table.Summary.Cell index={8} className="ammount-align">{hostessRewardData.map(finance => finance.totalOtherCosts).reduce((sum, current) => sum + current)} Kč</Table.Summary.Cell>
                                <Table.Summary.Cell index={9} className="ammount-align">{total} Kč</Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            </div>
        );
    }
}