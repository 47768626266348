export class BreadCrumbMock {
    href = '';
    text?= '';
}

export const loginBreadCrumb: BreadCrumbMock[] = [
    {
        href: '',
        text: 'login'
    },
];

export const errorBreadCrumb: BreadCrumbMock[] = [
    {
        href: '',
        text: 'home'
    },
    {
        href: '',
        text: '404'
    },
];

export const homeBreadCrumb: BreadCrumbMock[] = [
    {
        href: '/',
        text: 'pay-roll'
    },
    {
        href: '/',
        text: 'home'
    },
];

export const financeBreadCrumb: BreadCrumbMock[] = [
    {
        href: '/',
        text: 'pay-roll'
    },
    {
        href: '/finance',
        text: 'finance'
    },
];

export const tasksBreadCrumb: BreadCrumbMock[] = [
    {
        href: '/',
        text: 'pay-roll'
    },
    {
        href: '/tasks',
        text: 'tasks'
    },
];