import React from 'react';
import { Routes, Route } from "react-router-dom";
import NotFound from './pages.error/not-found';
import Login from './pages.public/login';
import Finance from './pages/finance';
import Home from './pages/home';
import { RootLayout } from './pages/layout';
import Tasks from './pages/tasks';

export function App() {
    return (
        <>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<RootLayout />}>
                    <Route index element={<Home />} />
                    <Route path="finance" element={<Finance />} />
                    <Route path="tasks" element={<Tasks />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
}