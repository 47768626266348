import React from 'react';
import { Header } from 'antd/lib/layout/layout';
import { LoginOutlined, RightOutlined, DownOutlined } from '@ant-design/icons';
import logo from '../resources/images/logo.svg';
import { Breadcrumb, Dropdown, Menu } from 'antd';
import { BreadCrumbMock } from '../data/breadcrumb-data';

const companiesList = [
    {
        key: 0,
        name: 'POS Media s.r.o.'
    },
    {
        key: 1,
        name: 'empea cz, s.r.o.'
    },
]

export default class HeaderMenu extends React.Component<{ breadCrumbData: BreadCrumbMock[], title: string }> {

    state = {
        collapsed: false,
        selectedCompany: 'POS Media s.r.o.',
    };

    handleCollapse = (collapsed: boolean) => {
        console.log(collapsed);
        this.setState({ collapsed });
    };

    onCompanyChanged(selectedCompany: string) {
        this.setState({ selectedCompany })
    }

    render() {

        const { breadCrumbData, title } = this.props;

        const companies = (
            <Menu>
                {companiesList.map((company, i) => (
                    <Menu.Item key={i} onClick={() => this.onCompanyChanged(company.name)}>
                        <label className="header-company-dropdown-item">{company.name}</label>
                    </Menu.Item>
                ))}
            </Menu>
        );

        return (
            <>
                <Header>

                    <img className="pos-logo" src={logo} alt="" />
                    <Breadcrumb style={{ margin: '16px 0' }} separator={<RightOutlined />}>
                        {breadCrumbData.map((item, i) => (
                            i < breadCrumbData.length - 1 ?
                                <Breadcrumb.Item key={i}>
                                    <a href={item.href} className="breadcrumb-text">{item.text}</a>
                                </Breadcrumb.Item>
                                :
                                <Breadcrumb.Item key={i}>{item.text}</Breadcrumb.Item>
                        ))}

                    </Breadcrumb>

                    <span className="user-profile">
                        <label className="user-name">Jaroslav Stroupežnický</label>
                        <Dropdown className="header-company-dropdown" overlay={companies} trigger={['click']}>
                            <span className="header-company-dropdown-body">
                                <label className="header-company-dropdown-title">{this.state.selectedCompany}</label>
                                <DownOutlined />
                            </span>
                        </Dropdown>
                        <a href="/login" className="a-no-style">
                            <LoginOutlined className="logout-icon" />
                        </a>
                    </span>

                </Header>

                <div className="logo-banner">
                    <label className="header-title">{title}</label>
                </div>
            </>
        );
    }
}