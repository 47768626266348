import React from 'react';
import { Button, DatePicker, Modal, Radio, RadioChangeEvent, Select } from 'antd';
import { financeBreadCrumb } from '../data/breadcrumb-data';
import HeaderMenu from '../components/header-component';
import CampaignDetail from '../components/campaign-detail';
import CampaignTotal from '../components/campaign-total';
import PersonDetail from '../components/person-detail';
import PeopleTotal from '../components/people-total';
import { FooterComponent } from '../components/footer-component';

const { Option } = Select;

const dimensionOptions = [
    { label: 'Campaign - detail', value: 'campaignDetail' },
    { label: 'Campaign - total', value: 'campaignTotal' },
    { label: 'Person - detail', value: 'personDetail' },
    { label: 'People - total', value: 'peopleTotal' },
];

export default class Finance extends React.Component {

    state = {
        dimension: 'campaignDetail',

        exportBillingsVisible: false,
        exportPeopleVisible: false,
        generateContractsVisible: false,
    }

    handleDimensionChange = (e: RadioChangeEvent) => {
        this.setState({
            dimension: e.target.value,
        });
    }

    //Modal
    handleShowExportBillingModal = (exportBillingsVisible: boolean) => {
        this.setState({ exportBillingsVisible });
    };

    handleCloseExportBillingModal = (exportBillingsVisible: boolean) => {
        this.setState({ exportBillingsVisible });
    };

    handleShowExportPeopleModal = (exportPeopleVisible: boolean) => {
        this.setState({ exportPeopleVisible });
    };

    handleCloseExportPeopleModal = (exportPeopleVisible: boolean) => {
        this.setState({ exportPeopleVisible });
    };

    handleShowGenerateContractModal = (generateContractsVisible: boolean) => {
        this.setState({ generateContractsVisible });
    };

    handleCloseGenerateContractModal = (generateContractsVisible: boolean) => {
        this.setState({ generateContractsVisible });
    };

    render() {
        const { dimension } = this.state;

        return (
            <div className="page-content">
                <HeaderMenu title="Finance" breadCrumbData={financeBreadCrumb} />

                <div className="financial-container">
                    <div className="financial-content-container">

                        {this.state.dimension === 'campaignDetail' && <CampaignDetail />}
                        {this.state.dimension === 'campaignTotal' && <CampaignTotal />}
                        {this.state.dimension === 'personDetail' && <PersonDetail />}
                        {this.state.dimension === 'peopleTotal' && <PeopleTotal />}

                    </div>

                    <div className="filter-container financial-filter-container">
                        <div className="title-line-container">
                            <label className="title-line-label">Dimenze</label>
                            <hr className="title-line-hr" />
                        </div>

                        <div className="filter-item">
                            <label className="gray-small-label">Zobrazení dle</label>
                            <Radio.Group className="dimension-select" options={dimensionOptions} value={dimension} onChange={(e) => this.handleDimensionChange(e)} optionType="button" buttonStyle="solid" />
                        </div>

                        <div className="filter-item margin-bottom-25">
                            <label className="gray-small-label">Obodbí</label>
                            <DatePicker />
                        </div>

                        <div className="title-line-container">
                            <label className="title-line-label">Filtr</label>
                            <hr className="title-line-hr" />
                        </div>

                        <div className="filter-item">
                            <label className="gray-small-label">Název kampaně</label>
                            <Select defaultValue="1">
                                <Option value="1">Lindor Xmass</Option>
                            </Select>
                        </div>

                        <div className="filter-item">
                            <label className="gray-small-label">Kód kampaně</label>
                            <Select defaultValue="1">
                                <Option value="1">P134/2019</Option>
                            </Select>
                        </div>

                        <div className="filter-item">
                            <label className="gray-small-label">Kód regionu</label>
                            <Select defaultValue="1">
                                <Option value="1">Praha</Option>
                            </Select>
                        </div>

                        <div className="filter-item">
                            <label className="gray-small-label">Role</label>
                            <Select defaultValue="1">
                                <Option value="1">Vše</Option>
                            </Select>
                        </div>

                        <div className="drawer-button-container">
                            <Button className="custom-button primary-button" type="primary" >Uložit</Button>
                            <Button className="custom-button default-button" type="default" >Reset</Button>
                        </div>

                    </div>

                </div>

                <Button className="custom-button default-button" type="default" onClick={() => this.handleShowExportBillingModal(true)} >Export campaign billings</Button>
                <Button className="custom-button default-button" type="default" onClick={() => this.handleShowExportPeopleModal(true)} >Export people to target</Button>
                <Button className="custom-button default-button" type="default" onClick={() => this.handleShowGenerateContractModal(true)} >Generate contracts</Button>

                <Modal title="Export campaign billings"
                    visible={this.state.exportBillingsVisible}
                    onOk={() => this.handleCloseExportBillingModal(false)}
                    onCancel={() => this.handleCloseExportBillingModal(false)}
                    centered>

                    <div className="title-line-container">
                        <label className="title-line-label">Export Summary</label>
                    </div>

                    <div className="card-item vertical-spacing">
                        <label className="gray-small-label">Persons to export</label>
                        <label className="data-label">1</label>
                    </div>

                    <div className="card-item vertical-spacing">
                        <label className="gray-small-label">Total amount</label>
                        <label className="data-label">1550</label>
                    </div>

                </Modal>

                <Modal title="Export people to target"
                    visible={this.state.exportPeopleVisible}
                    onOk={() => this.handleCloseExportPeopleModal(false)}
                    onCancel={() => this.handleCloseExportPeopleModal(false)}
                    centered>

                    <div className="title-line-container">
                        <label className="title-line-label">Export Summary</label>
                    </div>

                    <div className="card-item vertical-spacing">
                        <label className="gray-small-label">Persons to export</label>
                        <label className="data-label">0</label>
                    </div>

                </Modal>

                <Modal title="Generate contracts"
                    visible={this.state.generateContractsVisible}
                    onOk={() => this.handleCloseGenerateContractModal(false)}
                    onCancel={() => this.handleCloseGenerateContractModal(false)}
                    centered>

                    <div className="flex-row">
                        <div className="card-item half">
                            <DatePicker />
                        </div>
                        <div className="card-item half">
                            <DatePicker />
                        </div>
                    </div>

                    <div className="title-line-container">
                        <label className="title-line-label">Generate Contracts summary</label>
                    </div>

                    <div className="card-item vertical-spacing">
                        <label className="gray-small-label">Selected persons</label>
                        <label className="data-label">0</label>
                    </div>

                </Modal>

                <FooterComponent />
            </div>
        );
    }
}