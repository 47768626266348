import { useState } from 'react';
import { Link, Outlet, useLocation } from "react-router-dom";
import { Layout, Menu } from 'antd';
import { HomeOutlined, UserOutlined, BarChartOutlined } from '@ant-design/icons';

const { Sider } = Layout;

export function RootLayout() {

    const [collapsed, setCollapsed] = useState(true);

    const path = useLocation().pathname.substring(1);

    return (
        <>
            <Sider collapsible collapsed={collapsed} onCollapse={state => setCollapsed(state)} >
                <div className="menu-container">
                    <div className="logo" />
                    <Menu theme="dark" mode="inline" selectedKeys={[path == '' ? 'home' : path]} >
                        <Menu.Item key="home" icon={<HomeOutlined />}>
                            <Link to="/">Home</Link>
                        </Menu.Item>
                        <Menu.Item key="finance" icon={<BarChartOutlined />}>
                            <Link to="/finance">Financing</Link>
                        </Menu.Item>
                        <Menu.Item key="tasks" icon={<UserOutlined />}>
                            <Link to="/tasks">Tasks</Link>
                        </Menu.Item>
                    </Menu>
                </div>
            </Sider>

            <Layout className="site-layout">
                <Outlet />
            </Layout>

        </>
    );
}