import { Table } from 'antd';
import React from 'react';

export const hostessRewardCostData = [
    {
        id: 1,
        pNo: 'P134/2019',
        name: 'Lindor Xmass',
        dppSum: 10250,
        zlSum: 10250,
        dppTc: 1250,
        zlTc: 1050,
        dppOc: 1250,
        zlOc: 1050,
    },
    {
        id: 2,
        pNo: 'P212/2019',
        name: 'Rossmann',
        dppSum: 12380,
        zlSum: 12380,
        dppTc: 2220,
        zlTc: 3825,
        dppOc: 2220,
        zlOc: 3825,
    },
];

export default class CampaignTotal extends React.Component {

    render() {

        return (
            <div>

                <Table dataSource={hostessRewardCostData} pagination={false} rowKey={x => `${x.id}`}
                    columns={[
                        {
                            key: 'pNo',
                            title: 'P. No.',
                            render: (_, record) => <label className="pno-cell">{record.pNo}</label>,
                        },
                        {
                            key: 'name',
                            title: 'Name',
                            render: (_, record) => <>{record.name}</>,
                        },
                        {
                            key: 'dppSum',
                            title: 'DPP Sum',
                            align: 'right',
                            render: (_, record) => <>{record.dppSum} Kč</>,
                        },
                        {
                            key: 'zlSum',
                            title: 'ZL Sum',
                            align: 'right',
                            render: (_, record) => <>{record.zlSum} Kč</>,
                        },
                        {
                            key: 'dppZlSum',
                            title: 'DPP, ZL Sum',
                            className: 'financing-table-total',
                            align: 'right',
                            render: (_, record) => <>{record.dppSum + record.zlSum} Kč</>,
                        },
                        {
                            key: 'dppTc',
                            title: 'DPP TC',
                            align: 'right',
                            render: (_, record) => <>{record.dppTc} Kč</>,
                        },
                        {
                            key: 'zlTc',
                            title: 'ZL TC',
                            align: 'right',
                            render: (_, record) => <>{record.zlTc} Kč</>,
                        },
                        {
                            key: 'dppZlTc',
                            title: 'DPP, ZL TC',
                            className: 'financing-table-total',
                            align: 'right',
                            render: (_, record) => <>{record.dppTc + record.zlTc} Kč</>,
                        },
                        {
                            key: 'dppOc',
                            title: 'DPP OC',
                            align: 'right',
                            render: (_, record) => <>{record.dppOc} Kč</>,
                        },
                        {
                            key: 'zlOc',
                            title: 'ZL OC',
                            align: 'right',
                            render: (_, record) => <>{record.zlOc} Kč</>,
                        },
                        {
                            key: 'dppZlOc',
                            title: 'DPP, ZL OC',
                            className: 'financing-table-total',
                            align: 'right',
                            render: (_, record) => <>{record.dppOc + record.zlOc} Kč</>,
                        },
                    ]}
                    summary={() => (
                        <Table.Summary>
                            <Table.Summary.Row className="financing-summary">
                                <Table.Summary.Cell index={0}>Součet</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                <Table.Summary.Cell index={2} className="ammount-align">{hostessRewardCostData.map(finance => finance.dppSum).reduce((sum, current) => sum + current)} Kč</Table.Summary.Cell>
                                <Table.Summary.Cell index={3} className="ammount-align">{hostessRewardCostData.map(finance => finance.zlSum).reduce((sum, current) => sum + current)} Kč</Table.Summary.Cell>
                                <Table.Summary.Cell index={4} className="ammount-align">
                                    {hostessRewardCostData.map(finance => finance.dppSum).reduce((sum, current) => sum + current) + hostessRewardCostData.map(finance => finance.zlSum).reduce((sum, current) => sum + current)} Kč
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={5} className="ammount-align">{hostessRewardCostData.map(finance => finance.dppTc).reduce((sum, current) => sum + current)} Kč</Table.Summary.Cell>
                                <Table.Summary.Cell index={6} className="ammount-align">{hostessRewardCostData.map(finance => finance.zlTc).reduce((sum, current) => sum + current)} Kč</Table.Summary.Cell>
                                <Table.Summary.Cell index={7} className="ammount-align">
                                    {hostessRewardCostData.map(finance => finance.dppTc).reduce((sum, current) => sum + current) + hostessRewardCostData.map(finance => finance.zlTc).reduce((sum, current) => sum + current)} Kč
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={8} className="ammount-align">{hostessRewardCostData.map(finance => finance.dppOc).reduce((sum, current) => sum + current)} Kč</Table.Summary.Cell>
                                <Table.Summary.Cell index={9} className="ammount-align">{hostessRewardCostData.map(finance => finance.zlOc).reduce((sum, current) => sum + current)} Kč</Table.Summary.Cell>
                                <Table.Summary.Cell index={10} className="ammount-align">
                                    {hostessRewardCostData.map(finance => finance.dppOc).reduce((sum, current) => sum + current) + hostessRewardCostData.map(finance => finance.zlOc).reduce((sum, current) => sum + current)} Kč
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            </div>
        );
    }
}