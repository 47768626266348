import React from 'react';
import { Progress, Table } from 'antd';

export const financeRewardData = [
    {
        id: 1,
        source: 'HostessApp',
        hostNo: 'H5331',
        name: 'Markéta Dušková',
        empl: 'ZL',
        role: 'Hostess',
        rate: 110,
        hours: 14,
        totalEarnings: 3250,
        totalTravelCosts: 540,
        totalOtherCosts: 1000,
    },
    {
        id: 2,
        source: 'Limigo',
        hostNo: 'H5332',
        name: 'Jana Novotná',
        empl: 'DPP',
        role: 'Coordinator',
        rate: 130,
        hours: 7,
        totalEarnings: 0,
        totalTravelCosts: 540,
        totalOtherCosts: 1000,
    },
];

export default class CampaignDetail extends React.Component {

    render() {

        let total = 0;
        financeRewardData.forEach((finance, i) => {
            const row = (finance.rate * finance.hours) + finance.totalEarnings + finance.totalOtherCosts + finance.totalTravelCosts;
            total += row;
        });

        return (
            <div>
                <div className="financial-info-container">
                    <div className="card-container">
                        <div className="card-item one-third space-between">
                            <div className="card card-item vertical-spacing">
                                <label className="gray-small-label">Campaign No.</label>
                                <label className="data-label">P134/2019</label>
                            </div>

                            <div className="card card-item vertical-spacing">
                                <label className="gray-small-label">Campaign Name</label>
                                <label className="data-label">Lindor Xmass</label>
                            </div>
                        </div>
                        <div className="card-item row two-third">
                            <div className="card card-item half">
                                <label className="gray-small-label">Campaign From</label>
                                <label className="data-label company-spacing">Lindt a.s.</label>
                                <label className="data-label small bold">David Tomeš</label>
                                <label className="data-label small">+420 770 512 212</label>
                                <label className="data-label small"><a href="mailto:david.tomes@lindt.cz">david.tomes@lindt.cz</a></label>
                            </div>
                            <div className="card card-item half">
                                <label className="gray-small-label">Invoice To</label>
                                <label className="data-label company-spacing">6weeks production, s.r.o.</label>
                                <label className="data-label small bold">Jaroslav Stroupežnický</label>
                                <label className="data-label small">+420 785 992 986</label>
                                <label className="data-label small orange-font"><a href="mailto:jaroslav.stroupeznicky@6weeksproduction.cz">jaroslav.stroupeznicky@6weeksproduction.cz</a></label>
                            </div>
                        </div>
                    </div>

                    <div className="card-container">
                        <div className="card card-item one-third">
                            <label className="gray-small-label">Status</label>
                            <div className="status-locked-container">
                                <div className="campaign-status-container">
                                    <label className={'campaign-status running'}>Running</label>
                                </div>
                            </div>
                        </div>

                        <div className="card card-item two-third">
                            <label className="gray-small-label">Interval</label>
                            <span className="timeline-progress">
                                <label className="data-label">01.10.2021</label>
                                <Progress className="default-progress finance-campaign-detail-progress" percent={25} showInfo={false} strokeColor="#7db24a" strokeLinecap="square" size="small" trailColor="#d4eabd" />
                                <label className="data-label">31.12.2021</label>
                            </span>
                        </div>
                    </div>
                </div>

                <Table dataSource={financeRewardData} pagination={false} rowKey={x => `${x.id}`}
                    columns={[
                        {
                            key: 'source',
                            title: 'Source',
                            render: (_, record) => <label className="pno-cell">{record.source}</label>,
                        },
                        {
                            key: 'hostNo',
                            title: 'Host No.',
                            render: (_, record) => <label className="pno-cell">{record.hostNo}</label>,
                        },
                        {
                            key: 'name',
                            title: 'Name',
                            render: (_, record) => <>{record.name}</>,
                        },
                        {
                            key: 'empl',
                            title: 'EMPL',
                            render: (_, record) => <>{record.empl}</>,
                        },
                        {
                            key: 'role',
                            title: 'Role',
                            render: (_, record) =>
                                <div className="financing-role-container">
                                    <label className={`financing-role-dot ${record.role.toLowerCase()}`} >⬤</label>
                                    {record.role}
                                </div>,
                        },
                        {
                            key: 'rate',
                            title: 'Rate',
                            render: (_, record) => <>{record.rate} Kč/h</>,
                        },
                        {
                            key: 'hours',
                            title: 'Hours',
                            align: 'right',
                            render: (_, record) => <>{record.hours} h</>,
                        },
                        {
                            key: 'totalEarnings',
                            title: 'Total Earnings',
                            align: 'right',
                            render: (_, record) => <>{record.totalEarnings} Kč</>,
                        },
                        {
                            key: 'totalTravelCosts',
                            title: 'Total Travel Costs',
                            align: 'right',
                            render: (_, record) => <>{record.totalTravelCosts} Kč</>,
                        },
                        {
                            key: 'totalOtherCosts',
                            title: 'Total Other Costs',
                            align: 'right',
                            render: (_, record) => <>{record.totalOtherCosts} Kč</>,
                        },
                        {
                            key: 'total',
                            title: 'Total',
                            className: 'financing-table-total',
                            align: 'right',
                            render: (_, record) => <>{(record.rate * record.hours) + record.totalEarnings + record.totalOtherCosts + record.totalTravelCosts} Kč</>,
                        },
                    ]}
                    summary={() => (
                        <Table.Summary>
                            <Table.Summary.Row className="financing-summary">
                                <Table.Summary.Cell index={0}>Součet</Table.Summary.Cell>
                                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                <Table.Summary.Cell index={6} className="ammount-align">{financeRewardData.map(finance => finance.hours).reduce((sum, current) => sum + current)} h</Table.Summary.Cell>
                                <Table.Summary.Cell index={7} className="ammount-align">{financeRewardData.map(finance => finance.totalEarnings).reduce((sum, current) => sum + current)} Kč</Table.Summary.Cell>
                                <Table.Summary.Cell index={8} className="ammount-align">{financeRewardData.map(finance => finance.totalTravelCosts).reduce((sum, current) => sum + current)} Kč</Table.Summary.Cell>
                                <Table.Summary.Cell index={9} className="ammount-align">{financeRewardData.map(finance => finance.totalOtherCosts).reduce((sum, current) => sum + current)} Kč</Table.Summary.Cell>
                                <Table.Summary.Cell index={10} className="ammount-align">{total} Kč</Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />

            </div>
        );
    }
}