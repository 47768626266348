import { Button, DatePicker, Drawer, Input, Modal, Select, Table } from 'antd';
import React from 'react';
import { FooterComponent } from '../components/footer-component';
import HeaderMenu from '../components/header-component';
import { tasksBreadCrumb } from '../data/breadcrumb-data';
import filterIcon from '../resources/images/filter_icon.svg';

const { Option } = Select;

const tasks = [
    {
        id: 1,
        source: "HostessApp",
        date: "03. 03. 2022",
        pno: "P2201003",
        name: "Albert opening Moskevská",
        region: "Praha",
        rate: "150 Kč",
        hours: 8,
        earnings: "1000 Kč",
        travelCost: "300 Kč",
        otherCost: "500 Kč",
        total: "1800 Kč",
    },
]

export default class Tasks extends React.Component {

    state = {
        filterDrawerVisible: false,
    };

    //Drawer
    handleShowFilterDrawer = (filterDrawerVisible: boolean) => {
        this.setState({ filterDrawerVisible });
    };

    handleCloseFilterDrawer = (filterDrawerVisible: boolean) => {
        this.setState({ filterDrawerVisible });
    };

    render() {

        return (
            <div className="page-content">
                <HeaderMenu title="Tasks" breadCrumbData={tasksBreadCrumb} />

                <div className="filter-buttons">
                    <img className="filter-icon" src={filterIcon} alt="" onClick={() => this.handleShowFilterDrawer(true)} />
                </div>

                <Drawer title="Filter" placement="right" width={360} onClose={() => this.handleCloseFilterDrawer(false)} visible={this.state.filterDrawerVisible}>
                    <div className="filter-container">

                        <div className="filter-item">
                            <label className="gray-small-label">Name</label>
                            <Input />
                        </div>

                        <div className="filter-item">
                            <label className="gray-small-label">Phone</label>
                            <Input />
                        </div>

                        <div className="filter-item">
                            <label className="gray-small-label">E-mail</label>
                            <Input />
                        </div>

                        <div className="filter-item">
                            <label className="gray-small-label">Role</label>
                            <Select allowClear showSearch
                                filterOption={(input, option) =>
                                    option && option.children && input ? option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                                }>
                                <Option value="1">Hostess</Option>
                            </Select>
                        </div>

                        <div className="drawer-button-container">
                            <Button className="custom-button primary-button" type="primary" onClick={() => this.handleCloseFilterDrawer(false)}>Search</Button>
                            <Button className="custom-button default-button" type="default" onClick={() => this.handleCloseFilterDrawer(false)}>Reset</Button>
                            <Button className="custom-button default-button" type="default" onClick={() => this.handleCloseFilterDrawer(false)}>Close</Button>
                        </div>
                    </div>
                </Drawer>

                <Table className="scrollable-table custom-pagination" dataSource={tasks} rowKey={user => `${user.id}`} scroll={{ y: '100%' }} pagination={{ pageSize: 20 }}
                    summary={() => (
                        <Table.Summary fixed>
                            <Table.Summary.Row className="pagination-row">
                                <Table.Summary.Cell className="summary-cell" index={0} colSpan={7}>
                                    <div className="summary-container">
                                        <div className="summary-legend">

                                        </div>
                                    </div>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                    columns={[
                        {
                            key: 'source',
                            title: 'Source',
                            render: (_, record) => <>{record.source}</>,
                        },
                        {
                            key: 'pno',
                            title: 'P.No.',
                            width: 70,
                            render: (_, record) => <label className="pno-cell">{record.pno}</label>,
                        },
                        {
                            key: 'date',
                            title: 'Date',
                            render: (_, record) => <>{record.date}</>,
                        },
                        {
                            key: 'name',
                            title: 'Name',
                            render: (_, record) => <>{record.name}</>,
                        },
                        {
                            key: 'region',
                            title: 'Region',
                            render: (_, record) => <>{record.region}</>,
                        },
                        {
                            key: 'rate',
                            title: 'Rate',
                            render: (_, record) => <>{record.rate}</>,
                        },
                        {
                            key: 'hours',
                            title: 'Hours',
                            render: (_, record) => <>{record.hours}</>,
                        },
                        {
                            key: 'earnings',
                            title: 'Earnings',
                            render: (_, record) => <>{record.earnings}</>,
                        },
                        {
                            key: 'travelCost',
                            title: 'Travel Costs',
                            render: (_, record) => <>{record.travelCost}</>,
                        },
                        {
                            key: 'otherCost',
                            title: 'Other Costs',
                            render: (_, record) => <>{record.otherCost}</>,
                        },
                        {
                            key: 'total',
                            title: 'Total',
                            render: (_, record) => <>{record.total}</>,
                        },
                    ]}

                />

                <FooterComponent />
            </div>
        );
    }
}